<template>
  <div class="container">

    <div class="background-image"></div>
    <div class="background-cover"></div>


    <img class="airplane" src="../src/assets/plane.png" alt="Airplane" />

    <img class="app-logo" src="../src/assets/logo.png" alt="frreen APP Logo" />
    <img
      class="brand-logo"
      src="../src/assets/frreen.png"
      alt="frreen Brand Logo"
    />
    <img
      class="company-logo"
      src="../src/assets/balafrreen.png"
      alt="Company Logo"
    />

    <h1 class="coming-soon">COMING SOON</h1>

    <div class="text-wrapper">
      <h2 class="secondary-text">Download Our App and Enjoy Your Flight</h2>
      <button
        class="download-button"
        :class="{ 'fade-dropdown': fadeDropdown }"
        @click="toggleDropdown"
      >
        Download Now
        <transition name="dropdown">
          <div v-if="showDropdown" class="dropdown-content show">
            <a
              class="linktr-link"
              target="_blank"
              href="https://linktr.ee/frreen"
            >
              https://linktr.ee/frreen
            </a>
            <img class="qr-image" src="../src/assets/qr.png" alt="QR Code" />
          </div>
        </transition>
      </button>
    </div>

    <div class="social-logos">
      <a href="https://www.facebook.com/10frreen" target="_blank">
        <img src="../src/assets/facebook.png" alt="Facebook Logo" width="50" />
      </a>
      <a href="https://www.instagram.com/10frreens" target="_blank">
        <img
          src="../src/assets/instagram.png"
          alt="Instagram Logo"
          width="50"
        />
      </a>
    </div>

    <div class="header-links">
      <a class="header-link" @click="showAboutUsPopup = true">About</a>
      <a class="header-link" @click="showContactUsPopup = true">Contact Us</a>
    </div>
    <!-- 
    <about-us-popup v-if="showAboutUsPopup" class="about-us-popup" />
    <contact-us-popup v-if="showContactUsPopup" class="contact-us-popup" /> -->
  </div>
</template>

<script>
import { ref } from "vue";
// import AboutUsPopup from "./components/AboutUsPopup.vue";
// import ContactUsPopup from "./components/ContactUsPopup.vue";

export default {
  components: {
    // AboutUsPopup,
    // ContactUsPopup,
  },
  setup() {
    const showDropdown = ref(false);
    const showAboutUsPopup = ref(false);
    const showContactUsPopup = ref(false);
    const fadeDropdown = ref(false);

    const toggleDropdown = () => {
      if (showDropdown.value) {
        fadeDropdown.value = true;
        setTimeout(() => {
          showDropdown.value = false;
          fadeDropdown.value = false;
        }, 700); // Adjust the duration of the fade-out animation
      } else {
        showDropdown.value = true;
      }
    };

    return {
      showDropdown,
      showAboutUsPopup,
      showContactUsPopup,
      fadeDropdown,
      toggleDropdown,
    };
  },
};
</script>


<style>
@font-face {
  font-family: "Futura";
  src: url("../src/assets/futura.ttf") format("truetype");
}
@font-face {
  font-family: "IntroRust";
  src: url("../src/assets/IntroRust.otf") format("opentype");
}

body {
  padding: 0;
  margin: 0;
}
.container {
  position: relative;
  text-align: center;
  background-color: #f9f9f9;
  height: 100vh;
  overflow: hidden;
}

.background-cover {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.31);
}

.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../src/assets/background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 0;
}

.header-links {
  position: absolute;
  top: 45px;
  right: 33px;
  display: flex;
  align-items: center;
  gap: 25px;
  font-family: "Futura";
}

.header-link {
  color: #0b80b5;
  font-size: 22px;
  text-decoration: none;
  cursor: pointer;
}

.airplane {
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  animation: airplaneAnimation 59s linear infinite;
}

@keyframes airplaneAnimation {
  0% {
    transform: translateX(-120%);
  }
  100% {
    transform: translateX(120%);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(50px);
  }
}

.app-logo {
  position: absolute;
  top: 20px;
  left: 20px;
}

.brand-logo {
  position: absolute;
  bottom: 21px;
  left: 20px;
}

.company-logo {
  position: absolute;
  bottom: 15px;
  right: 20px;
}

.coming-soon {
  position: absolute;
  top: calc(15% - 50px);
  left: 50%;
  transform: translateX(-50%);
  font-size: 90px;
  color: #fff;
  width: 100%;
  font-family: "IntroRust";
  text-align: center;
  background: linear-gradient(
    180deg,
    #1788bc 21.29%,
    rgba(48, 145, 189, 0) 184.65%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

.text-wrapper {
  position: absolute;
  top: calc(31% - 20px);
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
}

.secondary-text {
  font-size: 32px;
  font-family: "Futura";
  color: #3091bd;
}

.download-button {
  position: relative;
  display: inline-block;
  font-family: "Futura";
  padding: 17px 30px;
  font-size: 23px;
  background: linear-gradient(158.26deg, #0b80b5 14.26%, #ffffff 329.26%);
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  margin-top: 16px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  pointer-events: none;
  z-index: 1;
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  max-width: 593px;
  width: 75%;
  color: #ffffff;
  border-radius: 21.5003px;
  padding: 16px;
  border-radius: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  cursor: auto;
  transform-origin: top;
  transform: scaleY(0);
  opacity: 0;
  max-height: 0;
  overflow: hidden;
  /* transition: max-height 3s ease, opacity 3s ease, transform 0.5s ease; */
}

.dropdown-content {
  font-size: 23px;
  line-height: 30px;
  text-align: center;
  max-width: 600px;
  color: #ffffff;
  border-radius: 21.5px;
  padding: 16px;
  border-radius: 4px;
  margin-top: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column-reverse;
  top: calc(100% - 8px);
  left: 50%;
  border-radius: 8px;
  padding: 16px;
  cursor: auto;
  transform-origin: bottom;
  transform: scaleY(1);
}

.dropdown-enter-active,
.dropdown-leave-active {
  opacity: 1;
  transition: max-height 0.5s ease-in, opacity 0.5s ease-in-out, transform 0.5s ease-out;
}

.dropdown-enter,
.dropdown-leave-to {
  opacity: 0;
  transform: scaleY(0);
}

.linktr-link {
  color: #fff;
  margin: 25px 0 15px 0;
  opacity: 0;
  animation: fadeIn 0.3s ease-in-out forwards;
}

.linktr-link:hover {
  color: #ff7300;
  transition: 0.5s ease-in-out all;
}

.qr-image {
  background: none;
  width: 100%;
  max-width: 200px;
  height: auto;
  opacity: 0;
  animation: fadeIn 0.5s ease-in-out forwards;
}

.social-logos {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.social-logos img {
  width: 16px;
  margin: 0 6px;
  transition: transform 0.5s ease-in-out forwards;
}

.social-logos img:hover {
  transform: scale(1.2);
}

@media screen and (max-width: 700px) {
  .secondary-text {
    font-size: 21px;
    padding: 10px 15px;
  }
  .coming-soon {
    font-size: 60px;
    top: calc(17% - 1px);
  }
  .app-logo {
    top: 18px;
    left: 18px;
    width: 70px;
  }

  .brand-logo {
    bottom: 18px;
    left: 18px;
    width: 110px;
  }

  .company-logo {
    position: absolute;
    bottom: 18px;
    right: 18px;
    width: 170px;
  }
}

@media screen and (max-width: 500px) {
  .secondary-text {
    font-size: 15px;
  }
  .coming-soon {
    font-size: 40px;
    top: calc(20% - 1px);
  }
  .app-logo {
    top: 16px;
    left: 16px;
    width: 60px;
  }

  .brand-logo {
    bottom: 16px;
    left: 16px;
    width: 90px;
  }

  .company-logo {
    position: absolute;
    bottom: 16px;
    right: 16px;
    width: 140px;
  }
}

@media screen and (max-width: 400px) {
  .secondary-text {
    font-size: 15px;
  }
  .coming-soon {
    font-size: 40px;
    top: calc(21% - 1px);
  }
  .app-logo {
    top: 14px;
    left: 14px;
    width: 50px;
  }

  .brand-logo {
    bottom: 14px;
    left: 14px;
    width: 80px;
  }

  .company-logo {
    position: absolute;
    bottom: 14px;
    right: 14px;
    width: 120px;
  }
}

@media screen and (max-width: 306px) {
  .secondary-text {
    font-size: 15px;
  }
  .coming-soon {
    font-size: 33px;
    top: calc(21% - 1px);
  }
  .app-logo {
    top: 12px;
    left: 12px;
    width: 40px;
  }

  .brand-logo {
    bottom: 12px;
    left: 12px;
    width: 70px;
  }

  .company-logo {
    position: absolute;
    bottom: 12px;
    right: 12px;
    width: 120px;
  }
}
</style>